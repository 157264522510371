
import { defineComponent, PropType, ref, watchEffect } from "vue";
export type Diary = {
  diaryId: number;
  author: string;
  contents: string;
  createdAt: string;
};

export default defineComponent({
  name: "diary-component",
  props: {
    diaryProp: {
      type: Array as PropType<Array<Diary>>,
      default: () => [],
      required: true,
    },
    pageProp: {
      type: Number,
      default: () => 1,
      required: true,
    },
  },
  setup(props) {
    var startPage = ref(Math.floor((props.pageProp - 1) / 10) * 10);
    watchEffect(() => {
      startPage.value = Math.floor((props.pageProp - 1) / 10) * 10;
    });
    return {
      startPage,
    };
  },
});
