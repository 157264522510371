import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("일기 보기")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: "/write" }, {
          default: _withCtx(() => [
            _createTextVNode("일기 쓰기")
          ]),
          _: 1
        }),
        _createTextVNode(" | ")
      ], 512), [
        [_vShow, _ctx.isLogin]
      ]),
      _withDirectives(_createVNode(_component_router_link, { to: "/login" }, {
        default: _withCtx(() => [
          _createTextVNode("로그인")
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.isLogin]
      ]),
      _withDirectives(_createVNode(_component_router_link, { to: "/logout" }, {
        default: _withCtx(() => [
          _createTextVNode("로그아웃")
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.isLogin]
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}