
import { defineComponent, toRefs } from "vue";
import { useListStore } from "./stores/list";

export default defineComponent({
  setup() {
    const list = useListStore();
    const { isLogin } = toRefs(list);
    function GetUserToken() {
      let userToken = localStorage.getItem("userToken");
      if (userToken != null) {
        list.Login(userToken);
      }
    }
    GetUserToken();
    return { isLogin };
  },
});
